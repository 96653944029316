import {Link as GatsbyLink} from "gatsby"
import {darken} from 'polished'
import * as React from "react"
import {css} from "styled-components"
import {styled} from "../../theme/theme"

export interface LinkProps {
    children?: React.ReactNode,
    to?: string,
    outline?: boolean,
    button?: boolean,
    block?: boolean,
    className?: string,
    style?: React.CSSProperties,
    onClick?: (e: any) => void,
    linkType: string,
    target?: "_blank" | "_self" | "_parent" | "_top",
}

const defaultProps: LinkProps = {
    button: false,
    linkType: "document",
}

export const ButtonStyles = css<any>`
  color: ${props => props.theme.buttonTextColor};
  background-color: ${props => props.theme.primaryColor};
  border: 2px solid #fff;
  font-family: ${({ theme }) => theme.headingFontFamily};
  line-height: 130%;
  padding: 12px 20px;
  font-weight: bold;
  font-size: 1.5rem;
  display: inline-block;
  width: auto;
  transition: background-color 0.3s, border 0.3s, color 0.3s, box-shadow 0.3s;
  border-radius: ${({ theme }) => theme.roundness};
  text-align: center;
  
  &:hover{
    background-color: ${props => darken(0.1, props.theme.primaryColor)};
    color: ${props => props.theme.buttonTextColor};
  }
  
  &.btn-outline{
    color: #3A454C;
    background-color: #F3F4F5;
    border: 1px solid #858C90;
    box-shadow: none;
    font-size: 1.125rem;
    
    &:hover{
      background-color: ${props => props.theme.secondaryColor};
      color: #fff;
      text-decoration: none;
      box-shadow: none;
    }
  }
  
  &.btn-block{
    display: block;
    //width: 100%;
  }
`

const StyledInternalLink = styled(GatsbyLink)<any>`
${props => props['data-button'] ? ButtonStyles : null}
`

const StyledExternalLink = styled.a<any>`
${props => props['data-button'] ? ButtonStyles : null}
`

export default function Link(props: LinkProps) {
    const {
        block,
        outline,
        button,
        linkType,
        to = "",
        children,
        className = "",
        target = "_self",
        style = {},
        onClick = () => null,
    } = props

    const formattedLinkType = linkType.toLowerCase().replace("link.", "")
    if (linkType === 'none' || !to) {
        // This is actually not a link. We use this to help in creating the dropdown menus
        return (<span className="label">{children}</span>)
    }

    if (formattedLinkType === "document") {
        // This is an internal link

        // make sure to force trailing slash
        const urlWithSlash = to.replace(/\/?$/, '/');
        
        return (
            <StyledInternalLink
                style={style}
                className={`${className} ${button ? "btn" : ""} ${outline ? "btn-outline" : ""} ${block ? " btn-block" : ""}`}
                to={urlWithSlash}
                onClick={(e: any) => onClick(e)}
                data-button={button}
            >
                {children}
            </StyledInternalLink>
        )
    }
    
    return (
        <StyledExternalLink
            className={`${className} ${button ? "btn" : ""} ${outline ? "btn-outline" : ""} ${block ? " btn-block" : ""}`}
            href={to}
            target={target}
            data-button={button}
            data-outline={outline}
            data-block={block}
            style={style}
            onClick={(e: any) => onClick(e)}
        >
            {children}
        </StyledExternalLink>
    )
}

Link.defaultProps = defaultProps