import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import * as React from "react"
import { ReactNode } from "react"
import Link from "../components/Link/Link"
import Navigation, { NavigationLinks } from "../components/Navigation/Navigation"
import linkHelper, {
  linkResolver,
  prismicLinkMaker,
  query as LinkFragment,
} from "./utils/linkResolver"
import { Col, Container, Row } from "../components/Grid/Grid"
import htmlSerializer from "./utils/htmlSerializer"
import { RichText } from 'prismic-reactjs'

export function PrismicNavigation() {

  function createLink(linkData: any): ReactNode {
    const { label, link, style } = linkData
    const url = linkHelper.url(link, linkResolver)

    return (
      <Link
        linkType={link ? link._linkType : "none"}
        to={url}
        button={style === 'Button'}
      >
        {label}
      </Link>
    )
  }

  return (
    <StaticQuery
      query={`${query}`}
      render={data => {
        const menu = data.prismic.allMain_menus.edges.slice(0, 1).pop()
        const {banner_url = null, banner_text = null, banner_color = null, above_menu_text = ""} = menu.node;

        const links = menu.node.body.reduce((prev: NavigationLinks[], menuItem: any, index: number) => {
          const { primary: link } = menuItem
          let menu = {
            link: createLink(link),
            sub: menuItem.fields.map((link: any) => {
              return { link: createLink(link), sub: [] } // these menus only go one level deep
            }),
          }

          prev.push(menu)
          return prev
        }, [])

        return <Navigation 
          aboveMenuText={RichText.render(above_menu_text, linkResolver, htmlSerializer)}
          menuItems={links} 
          bannerUrl={prismicLinkMaker({button_text: banner_text, button_url: banner_url})}
          bannerText={banner_text}
          bannerColor={banner_color}
        />
      }}
    />)
}

export const query = graphql`
    query navQuery{
        prismic {
            allMain_menus {
                edges {
                    node {
                        _meta{
                            id
                        }
                        above_menu_text
                        banner_text
                        banner_url{
                            ... Linkable
                        }
                        banner_color
                        body {
                            ... on PRISMIC_Main_menuBodyMenu_item {
                                primary {
                                    label
                                    style
                                    link {
                                        ... Linkable
                                    }
                                }
                                fields {
                                    label
                                    link {
                                        ... Linkable
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`