import * as React from "react"
import { useState } from "react"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { media } from "styled-bootstrap-grid"
import { styled } from "../../theme/theme"
import {Row, Container, Col} from "../Grid/Grid"
import Link, { LinkProps } from "../Link/Link"
import {readableColor} from "polished"
import Helmet from "react-helmet"

export interface NavigationLinks {
  link: React.ReactNode,
  sub: NavigationLinks[] | []
}

type NavigationProps = {
  collapseWidth?: string,
  menuItems?: NavigationLinks[],
  bannerUrl?: LinkProps | null,
  bannerText: string | null,
  bannerColor: string | null,
  aboveMenuText?: string | null,
}

const StyledBanner = styled(Container)`
  &, a, a:visited, a:hover, a:active{
    color: ${props => props.color ? `${readableColor(props['color'])}` : '#000'};
  }
  
  z-index: 999;
  text-align: center;
  background-color: ${props => props.color ?? '#c7da7f' };
  font-weight: bold;
  padding: 0.5rem;
  position: fixed;
  top: 0;
`

const StyledHeader = styled.header<any>`
  ${props => props.hasBanner && `
    margin-top: 48px;
  `}
  
  background-color: ${props => props.theme.secondaryColor};
  font-size: 1.125rem;
  
  @media(max-width: ${props => props.collapseWidth}){
    box-shadow: 0px 0px 5px 0px #00000030;
  }
  
  .header-container{
    @media(min-width: ${props => props.collapseWidth}){
      padding-top: 0;
    }
  }
  
  .nav{
    display: flex;
    //padding-top: 10px;
    //padding-bottom: 10px;
  }
  
  .nav-menu{
    flex-direction: column;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .site-logo{
    display: flex;
    align-items: flex-end;
    
    img{
      width: 100%;
      max-width: 160px;
      @media(min-width: ${props => props.collapseWidth}){
        max-width: 280px;
      }
    }
  }
  
  .above-menu, .nav-list{
    display: none;
    @media(min-width: ${props => props.collapseWidth}){
     display: flex;
    }
  }
  
  .above-menu{
    background-color: ${props => props.theme.primaryColor};
    color: #fff;
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem;
    padding-left: 35px;
    padding-right: 20px;
    
    line-height: 100%; 
    border-bottom-left-radius: 20px;
    margin-bottom: 1.75rem;
    
    a:hover{
      color: #C9CDCE;
    }
    
    p{
      font-size: 1.125rem;
    }
  }
  
  a, span{
    color: #fff;
    font-family: ${props => props.theme.headingFontFamily};
    font-weight: 600;
    &:hover{
      color: #C9CDCE;
    }
  } 
  
  a.btn{
    color: #fff;
  }
  
  ul.nav-list{
    text-align: right;
    padding: 0;
    margin-bottom: 0;
    align-self: flex-end;
    transition: .5s transform;
    
    li{
      position: relative;
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      align-items: center;
      margin-right: 25px;
      white-space: nowrap;
      cursor:pointer;
      //line-height: 46px;
      
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        //margin-right: -0.25em; /* Adjusts for spacing */
      }
      
      span{
        position: relative;
      }
      
      span:after{
        font-size: 26px;
        position: absolute;
        top: -10px;
        right: -16px;
        content: " \u032C";
        line-height: 20px;
        color: ${props => props.theme.primaryColor};
      }
      
      :hover{
    
        ul{
          visibility: visible;
          pointer-events: auto;
          opacity: 1;
          margin-top: -3px;
          a{
            padding-bottom: 10px;
            padding-right: 20px;
          }
        }
      }
      
      &:first-child{
        margin-left: 35px;
      }
      
      ${media.xl`
        margin-right: 45px;
      `}
      
      &.active a{
        color: #414141;
        text-decoration: underline;
      }
      
      &:last-child{
        margin-right: 0;
      }
    }
    
    // dropdown menu
    ul{
      display: flex;
      opacity: 0;
      margin-top: 10px;
      visibility: hidden;
      pointer-events: none;
      flex-direction: column;
      position: absolute;
      background: #fff;
      right: 0;
      //transform: translateY(50%);
      z-index: 9;
      transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      
      li{
        padding: 0;
        margin: 0;
        
        &:before {
          display: none;
        }
        
        a{
          padding: 0;
          padding-top: 10px;
          padding-left: 10px;
          display: block;
          color: #000;
          transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
        }
      }
    }
  }
`

const StyledDrawer = styled.div`
  display: flex;
  @media(min-width: ${props => props.collapseWidth}){
     display: none !important;
  }
  
  background: #fff;
  z-index: 3;
  padding: 2rem 0;
  max-width: 80vw;
  min-width: 50vw;
  flex-direction: column;
  align-items: flex-end;
  //overflow-y: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  box-shadow: 0px 0 10px 0px rgba(0,0,0,0.55);
  transition: 0.3s;
  overflow-x: hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling:touch;
  
  &.open{
    transform: translateX(-100vw);
    display: flex;
  }

  ul {
    display: flex; // Toggled State using the :checked pseudo-class
    flex-direction: column;
    text-align: right;
    padding: 0;
    width: 100%;
    //border-top: 1px solid #ebebeb;
    margin-top: 20px;
    li{
      list-style: none;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      color: ${props => props.theme.primaryColor};
      font-weight: bold;
      
      .label:after{
        content: " >";
      }
      
      a, span{
        color: ${props => props.theme.secondaryColor};
        font-family: ${props => props.theme.headingFontFamily};
        font-weight: 600;
        &.btn{
          color: #fff;
        }
      } 
    }
    
    a, .label{
      display: block;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1.5rem;
    }
    
    li > ul{
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        background: #fff;
        flex-direction: column;
        align-items: flex-end;
        overflow-y: scroll;
        right: -100%;
        transition: 0.3s;
        
        &.open{
          transform: translateX(-100%);
        }
    }
  }
`

const StyledNavToggle = styled.div<any>`
      display: block;
      
      @media(min-width: ${props => props.collapseWidth}){
        display: none !important;
      }
      
      width: 18px;
      margin-left: auto;
      margin-right: 20px;
      cursor: pointer;

      div {
        background-color: #fff;
        width: 18px;
        height: 2px;
        margin-bottom: 3px;
        transition: transform 0.3s, opacity 0.3s;

        &:first-child {
          transform-origin: top left;
        }

        &:last-child {
          transform-origin: top left;
          margin-bottom: 0;
        }
      }
      
      &.light div{
        background-color: #000;
      }

    &.open {
      div {
        &:first-child {
          transform: rotateZ(45deg) translate(0px, -1px);

        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: rotateZ(-45deg) translate(-2px, 1px);
        }
      }
    }
`

class Navigation extends React.Component<NavigationProps, { menuOpen: boolean }> {

  private navListRef = React.createRef<HTMLUListElement>();
  private targetScrollLockElement = null;

  public constructor(props: NavigationProps) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }
  
  public componentDidMount(): void {
    this.targetScrollLockElement = this.navListRef.current;
  }

  public componentWillMount(): void {
    if (typeof document !== "undefined") {
      document.addEventListener("mousedown", this.handleClick.bind(this), false)
    } 
  }

  public componentWillUnmount(): void {
    if (typeof document !== "undefined") {
      document.removeEventListener("mousedown", this.handleClick.bind(this), false)
    }
    clearAllBodyScrollLocks();
  }

  public handleClick(e: any) {
    if (this.state.menuOpen &&
      this.navListRef &&
      this.navListRef.current &&
      !this.navListRef.current.contains(e.target)
    ) {
      this.setState({ menuOpen: false })
    }
  }

  public toggleMenu() {
    const menuOpen = !this.state.menuOpen;
    
    if(menuOpen){
      disableBodyScroll(this.targetScrollLockElement);
    } else {
      enableBodyScroll(this.targetScrollLockElement);
    }
    
    this.setState({ menuOpen })
  }

  public renderMenuItem(menuItem: NavigationLinks, key: number) {
    const { link, sub = [] } = menuItem
    return (
      <li key={"menuItem" + (key++ + Math.random())}>
        {link}
        {sub.length > 0 && <ul>{sub.map(subMenuItem => this.renderMenuItem(subMenuItem, key))}</ul>}
      </li>
    )
  }

  public render() {
    const { menuOpen } = this.state
    const { 
      menuItems = [], 
      bannerText = null, 
      bannerUrl = null, 
      bannerColor = null,
      aboveMenuText = "",
    } = this.props
    
    const hasBanner = bannerText && bannerUrl;
    let key = 0

    return (
      <React.Fragment>
        {hasBanner &&
        <StyledBanner color={bannerColor} fluid>
          <Helmet
            bodyAttributes={{
              class: 'has-banner'
            }}
          />
          <Row>
            <Col col={12}>
              <Link {...bannerUrl}>{bannerText}</Link>
            </Col>
          </Row>
        </StyledBanner>  
        }
        <StyledHeader hasBanner={hasBanner}>
          <Container className={'header-container'}>
            <Row>
              <Col col={12} className={"nav"}>
                <div className="site-logo">
                  <Link to={"/"}>
                    <img src={'/images/logo.png'} alt={'Logo'}/>
                  </Link>
                </div>
                <div className={`nav-menu`}>
                  <div className={"above-menu"}>
                    {aboveMenuText}
                  </div>
                  <StyledNavToggle
                    className={`${menuOpen ? " open" : ""}`}
                    onClick={() => this.toggleMenu()}
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </StyledNavToggle>

                  <ul className={`nav-list`}>
                    {menuItems.map((menuItem) => {
                      key++
                      return this.renderMenuItem(menuItem, key)
                    })}
                  </ul>

                </div>
              </Col>
            </Row>
          </Container>
          <div style={{backgroundColor: '#C9CCCD', borderBottom: "1px solid #B9BCBE", width: "100%", height: 16, boxShadow: "0px 2px 4px rgba(17, 35, 43, 0.05)"}}></div>
        </StyledHeader>

        <StyledDrawer ref={this.navListRef} className={`${menuOpen ? " open" : ""}`}>
          <StyledNavToggle className={`${menuOpen ? " open" : ""} light`} onClick={() => this.toggleMenu()}>
            <div></div>
            <div></div>
            <div></div>
          </StyledNavToggle>

          <MobileMenu menuItems={menuItems} isOpen={true}/>

        </StyledDrawer>
      </React.Fragment>
    )
  }
}

const MobileMenu = ({ menuItems, isOpen = false, onBackClick = null }) => {

  return (
    <ul className={isOpen ? " open" : ""}>

      {typeof onBackClick === "function" &&
      <li style={{ padding: "1.5rem" }} onClick={onBackClick}>&lt; Back</li>
      }

      {menuItems.map((menuItem, index) => {
        const { link, sub = [] } = menuItem

        return <MenuLink key={'mobile' + index} link={link} sub={sub}/>
      })}
    </ul>
  )
}

const MenuLink = ({ link, sub = [] }) => {
  const [isSubOpen, setIsSubOpen] = useState(false)

  return (
    <li
      onClick={sub.length ? (e) => setIsSubOpen(!isSubOpen) : () => null}
    >
      {link}
      {!!sub.length &&
      <MobileMenu
        menuItems={sub}
        isOpen={isSubOpen}
        onBackClick={() => setIsSubOpen(!isSubOpen)}
      />
      }
    </li>
  )
}

StyledHeader.defaultProps = {
  collapseWidth: "800px",
}

StyledNavToggle.defaultProps = {
  collapseWidth: "800px",
}

StyledDrawer.defaultProps = {
  collapseWidth: "800px",
}

export default Navigation