import * as React from "react"
import { linkResolver } from "./linkResolver"
import Link from "../../components/Link/Link"
import PrismicDOM from "prismic-dom"
import uuidv4 from "./uuidv4"
import {slugify} from "./slugify"

const Elements = PrismicDOM.RichText.Elements;

export default function(type, element, content, children) {
  switch (type) {
    case Elements.heading1: return <h1 id={slugify(element.text)}><span>{children}</span></h1>;
    case Elements.heading2: return <h2 id={slugify(element.text)}><span>{children}</span></h2>;
    case Elements.heading3: return <h3 id={slugify(element.text)}><span>{children}</span></h3>;
    case Elements.heading4: return <h4 id={slugify(element.text)}><span>{children}</span></h4>;
    case Elements.heading5: return <h5 id={slugify(element.text)}><span>{children}</span></h5>;
    case Elements.heading6: return <h6 id={slugify(element.text)}><span>{children}</span></h6>;
    case Elements.hyperlink:
      const target = element.data.target ?? "";
      const linkUrl = PrismicDOM.Link.url(element.data, linkResolver)

      return (
        <Link
          key={uuidv4()}
          linkType={element.data.link_type}
          to={linkUrl}
          target={target}
        >
          {children}
        </Link>
      )

    default:
      return null
  }
};