import React from "react"
import { styled } from "../../theme/theme"
import { Col, Container, Row } from "../Grid/Grid"
import Link from "../Link/Link"
import { mb } from "../../utils/spacing"

interface FooterProps {
  links?: React.ReactNode[],
  children?: any,
}

const StyledContainer = styled(Container)`
background-color: ${props => props.theme.secondaryColor};
`

const StyledFooter = styled(Row)`
  padding-top: 2rem;
  color: #fff;
  
  small, small a{
    color: #858C90
  }
  
  a{
    color: #fff;
  }
  
  p{
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  
  h3{
    color: #fff;
    border: none;
  }
  
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    ${mb(2)};
  }
  
  ul.social{
    display: flex;
    li{
      margin-right: 30px;
    }
  }
  
  .heading{
    font-size: 1rem;
    font-weight: bold;
  }
`

export default function Footer(props: FooterProps) {

  return (
    <StyledContainer fluid>
      <Row>
        <Container>
          <StyledFooter>
            <Col col={12} md={4}>
              <img src={"/images/logo.png"} alt={"Logo"}/>
              <p style={{ marginTop: 10, fontSize: "18px" }}>
                PO Box 9 - 211-2nd Ave, Rosenort, MB R0G 1W0<br/>
              </p>

              <p style={{ fontSize: "21px" }}>
                <strong>
                  <span style={{color: '#858C90'}}>P:</span> <a href={"tel:2047468554"} target={"_blank"}>204.746.8554</a><br/>
                  <span style={{color: '#858C90'}}>E:</span> <a href={"mailto:ck@accutwist.com"} target={"_blank"}>ck@accutwist.com</a>
                </strong>
              </p>

              <small>© Accutwist Inc.  |  All Rights Reserved  |  <Link to={"/privacy-policy"}>Privacy Policy</Link></small>
            </Col>
            <Col col={12} md={6} mdOffset={2}>
              <h3>Interested In Working With Us?</h3>
              <p>Give us a call, send us an email, or stop by our Rosenort location anytime to discuss the flighting you need for your equipment.</p>
              <Link button={true} to={"/contact-us"}>Contact Us</Link>
            </Col>
          </StyledFooter>
        </Container>
      </Row>
    </StyledContainer>
  )
}